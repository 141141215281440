import React from 'react';
import { FormattedDate } from 'react-intl';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import path from 'path-browserify';

import Tag from '../Tag';
import StrapiLink from '../StrapiLink/StrapiLink';
import { RichTextField } from '../RichTextField/RichTextField';

import * as styles from './HeroAlt.module.scss';

export interface HeroAltProps {
  variant: 'default' | 'blog';
  title: string;
  image?: string;
  tags?: string[];
  date?: string;
  excerpt?: string;
  slug?: string;
  ctaText?: string;
  richtext?: string;
  linkText?: string;
}

const HeroAlt = ({ image, title, tags, date, excerpt, slug, variant, ctaText, richtext, linkText }: HeroAltProps) => (
  <div className={styles.heroAlt} data-testid='heroAlt-component'>
    <Helmet>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={excerpt} />
      <meta property="og:image" content={image} />
    </Helmet>

    <div className={styles.featuredImage}>{image ? <img className={styles.image} src={image} data-testid='image' /> : null}</div>
    {variant === 'blog' ? (
      <div className={classnames(styles.content, styles.contentBlog, 'content')} data-testid='blog-content'>
        <time className={`is-5 title mb-1 black-4`} dateTime={date}>
          <FormattedDate value={date} day="2-digit" month="short" year="numeric" />
        </time>
        {slug ? (
          <h1>
            <Link to={slug} className="title is-block is-2 brand-a mt-0 mb-3" data-testid='link-component'>
              {title}
            </Link>
          </h1>
        ) : null}
        <p className="mb-3">{tags && tags?.map((tag: string) => <Tag isClickable={false} key={tag} name={tag} />)}</p>
        <RichTextField className="is-text-2 black-2" content={excerpt} />
        {slug ? (
          <div className="read-more">
            <StrapiLink url={slug} title={linkText ?? ''} />
          </div>
        ) : null}
      </div>
    ) : (
      <div className={classnames(styles.content, styles.contentDefault, 'content')}>
        <div className={styles.contentDefaultMiddle}>
          <h1 className="title is-1 brand-a mt-0 mb-3">{title}</h1>
          <p className="is-text-2 black-2">{excerpt}</p>
          {richtext?.length ? <RichTextField className="is-text-2 black-2" content={richtext} data-testid="mockedRichTextField" /> : null}
        </div>

        {ctaText && slug ? <StrapiLink url={slug} title={ctaText} /> : null}
      </div>
    )}
  </div>
);

export default HeroAlt;
